
import React, { useState, useEffect } from 'react';
import heroImg from '../../assets/data/images/laptop-poses.webp';
import windowImg from '../../assets/data/images/window.png';
import Fb from '../../assets/data/images/facebook.png'
import Ins from '../../assets/data/images/instagram.png'
import Linkedin from '../../assets/data/images/linkedin-removebg-preview.png'
import Planes3d from '../../assets/data/images/3d objdcts/1.png'
import Planesother from '../../assets/data/images/3d objdcts/plane2.png'
import CountUp from 'react-countup';

// import bgBanner from '../../assets/data/images/'
import '../../App.css'



const Hero = ({ mode }) => {

  const [themeMode, setThemeMode] = useState(mode)

  useEffect(() => {
    setThemeMode(mode)

  }, [mode])

  return (
    <section className={` relative pt-0 mt-20 pb-20 text- ${themeMode ? "dark h-auto" : ""} `} id='home'>

      <div className="pt-14 container">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row ">
          {/* ============= hero left content ============= */}

          <div className=" md:basis-1/2 my-5 z-20 ">
            {/* <h5 data-aos="fade-right" data-aos-durantion="2000" className='text-headingColor font-[600]'>Hello World!</h5> */}

            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className=' font-[800] text-[2.5rem] max-sm:text-[40px] my-5 leading-18 '
            >
              <strong> The Creative Agency </strong>
              <p className='font-[400] text-[1.4rem]'> Bringing You To The Digital World: </p>
              <p className='font-[400] text-[1.4rem]'> <p className='inline text-secondaryHeading text-[1.8rem]'>AXZO Tech</p>-Your Partner for Exceptional UI/UX Design, Web Development and Writing Services</p>
            </h1>

            <div
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200"
              className='flex items-center gap-6 mt-4 mb-9'
            >
              <a href="#contact">
                <button className={`bg-buttonColor button py-3 px-2 text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-1.5 px-4 rounded-[8px] `}>
                  <i className="ri-discuss-line"></i> Contact Us
                </button>
              </a>
              <a href="#about" className={` font-[600] text-[16px] border-b border-solid border-smallTextColor`}>
                Know Us!
              </a>
            </div>



            <div className="flex items-center gap-9 mt-8 mb-12">
              <span>
                <p className='flex text-[15px] font-[600]'> Follow Us On: </p>
              </span>
              <span>
                <button className='flex items-center gap-1  font-[600] rounded max-h-[30px]' >
                  <a href="https://www.facebook.com/profile.php?id=100088242094302">
                    <img className='social-icons' src={Fb} alt="" width={25} style={!themeMode ? { filter: "drop-shadow(1px 3px 8px #71b1e2)" } : {}} />
                  </a>
                </button>
              </span>

              <span>
                <button className='flex items-center gap-1 font-[600] rounded max-h-[30px] 
                            ease-in duration-300' >
                  <a href="https://www.linkedin.com/company/axzotech/mycompany/"
                  >
                    <img className='social-icons' src={Linkedin} alt="" width={35} style={!themeMode ? { filter: "drop-shadow(1px 3px 8px #71b1e2)" } : {}} />
                  </a>
                </button>
              </span>

              <span>
                <button className='flex items-center gap-1  font-[600] rounded max-h-[30px] 
                            ease-in duration-300' >
                  <a href="https://www.instagram.com/axzotech?igsh=OWVkMTFiN2gwcnB3"       >
                    <img className='social-icons' src={Ins} alt="instagram" width={28} style={!themeMode ? { filter: "drop-shadow(1px 3px 8px #71b1e2)" } : {}} />
                  </a>
                </button>
              </span>

            </div>

            </div>

            <span
          data-aos="fade-up-left"
          data-aos-duration="1000"
          data-aos-delay="500"
          className='absolute right-[90%] top-[45%] max-md:hidden'
        >
          <img src={Planesother} alt="" width={"160px"} />
        </span>


          

          {/* ============= hero left end ============= */}

          {/* ============ hero right ================== */}
 
          <div className="basis-1/3 ml-0 sm-mt-0">
            <figure className="flex items-center justify-center position-relative" style={{  }}>
              <img className={`hero-img w-[20vw] mt-[-20%]  ${!themeMode ? "shadow-img" : ""}`} src={windowImg} alt="logo img"  />
              <img className={`hero-img   w-[30vw] ml-[-20%] ${!themeMode ? "shadow-img" : ""}`} src={heroImg} alt="logo img"  />
              {/* <img className='hero-img absolute left-140' src={homeImg} alt="logo img" style={{width:"30%"}} /> */}
              {/* <img src="" alt="" /> */}
            </figure>

            </div>
            <span
              data-aos="fade-up-right"
              data-aos-duration="1000"
              data-aos-delay="500"
              className='absolute top-64 right-1/2 max-md:hidden'
            >
              <img src={Planes3d} alt="" width={"150px"} />
            </span>
           
        </div>
        

        <div className=" my-6 ">
          <div className="flex justify-between max-md:justify-center flex-wrap  md:basis-1/3 flex text-center mt-4  md:mt-0    ">

            <div className={`w-[300px] items-center  mt-8 bg-boxColors  text-smallTextColor  p-5 rounded-lg ${themeMode?"boxInDark":""} `} >
              <h3 className='font-[600] text-[20px]'>Over</h3>
              <h2 className=' font-[700] text-[32px] text-secondaryHeading'>
                <CountUp start={0} end={1300} duration={3} suffix='+' />
              </h2>
              <p className='text-[1.1rem]'>Projects Completed</p>
            </div>
            <div className={`w-[300px] items-center  mt-8 bg-boxColors  text-smallTextColor  p-5 rounded-lg ${themeMode?"boxInDark":""} `} >
              <h3 className='font-[600] text-[20px]'>Around</h3>
              <h2 className='font-[700] text-[32px] text-secondaryHeading'>
                <CountUp start={0} end={500} duration={3}  />
              </h2>
              <p className='text-[1.1rem]'>Repeated Clients</p>
            </div>
            <div className={`w-[300px] items-center  mt-8 bg-boxColors  text-smallTextColor  p-5 rounded-lg ${themeMode?"boxInDark":""} `}>
              <h3 className='font-[600] text-[20px]'>Over</h3>
              <h2 className='font-[700] text-[32px] text-secondaryHeading'>
                <CountUp start={0} end={10} duration={3} suffix='+' />
              </h2>
              <p className='text-[1.1rem]'>Years of Experience</p>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Hero
