import React, { useEffect, useRef, useState } from 'react';
// import Logo from '../../assets/data/images/logo.png'
import LogoName from '../../assets/data/images/logoname.png'
import Logolight from '../../assets/data/images/logo-light.png'

import menuDark from '../../assets/data/images/hamburgerDark.png'
import menuLight from '../../assets/data/images/hamburgerLight.png'

import '../../App.css';

const Header = ({ mode }) => {
    const menuRef = useRef(null);

    // const [currentUrl, setcurrentUrl] = useState(window.location.href)
    // const [navbarClass, setnavbarClass] = useState(false)
    // console.log(mode)
    const [themeMode, setThemeMode] = useState(mode)

    useEffect(() => {
        setThemeMode(mode)

    }, [mode])


    // console.log(themeMode)



    const toggleMenu = () => {
        menuRef.current.classList.toggle('show__menu');
        
    }




    return (
        <header id='header' className={`nav-shadow  fixed top-0 w-full h-auto leading-[80px] flex items-center mb-8  bg-opacity-100 z-40 ${themeMode ? 'bg-blackNav' : 'bg-primaryColor'}`}>
            <div className="container">
                <div className="flex items-center justify-between">
                    {/* {++++++++++++logo ++++++} */}

                    <div className='flex items-center gap-[10px] text-align-center'>
                        <span className='w-[115px] max-sm:w-[80px] h-[75px]  text-[18px] font-[500] rounded-full flex items-center justify-center '>
                            <img src={themeMode ? Logolight : LogoName} alt="" />
                        </span>

                    </div>

                    {/* {++++++++++++logo end ++++++} */}
                    {/* {++++++++++++Menu start ++++++} */}

                    <div className='menu' ref={menuRef} onClick={toggleMenu}>
                        <ul className='flex items-center justify-between gap-10'>
                    <button  className='max-md:block'><i className="close max-md:block ri-close-line"></i></button>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading    ' href="#home"> <button className='focus:font-[600] hover:underline decoration-solid'>Home </button> </a>  </li>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading  ' href="#services"><button className='focus:font-[600] hover:underline decoration-solid'>  Services </button> </a> </li>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading  ' href="#about"> <button className='focus:font-[600] hover:underline decoration-solid'> About </button> </a> </li>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading   ' href="#portfolio"> <button className='focus:font-[600] hover:underline decoration-solid'> Projects </button> </a> </li>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading   ' href="#review"> <button className='focus:font-[600] hover:underline decoration-solid'> Reviews </button> </a> </li>
                            <li> <a className=' font-[500]   focus:text-secondaryHeading   ' href="#contact"> <button className='focus:font-[600] hover:underline decoration-solid'> Contact Us </button> </a> </li>
                        </ul>
                    </div>
                    {/* {++++++++++++Menu end ++++++} */}

                    {/* {++++++++++++Right button ++++++} */}

                    <div className="flex items-center gap-4">
                        <button className=' button flex items-center gap-2 text-white bg-buttonColor font-[600]  p-4 rounded-[8px] max-h-[45px] max-md:hidden max-md:w-fit 
                            hover:bg-smallTextColor hover:text-white hover: font-[500] ease-in duration-300' >
                            <i className="ri-send-plane-fill"></i><a href="https://join.skype.com/invite/peT5gO7lOD3E" className='font-[400]'>Book a Meeting</a>
                        </button>
                        
                        <span onClick={toggleMenu} className={`menuIcon text-2xl text-smallTextColor md:hidden cursor-pointer ${themeMode ? "menu-icon" : ""}`}>
                            <img src={!themeMode ? menuDark : menuLight} width={"40px"} alt="" />
                        </span>
                    </div>

                    {/* {++++++++++++Right button end ++++++} */}



                </div>
            </div>



        </header>

    )
}

export default Header;


