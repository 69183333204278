import React, {useState, useEffect} from 'react'
import MobileImg from '../../assets/data/images/mobile.png'
import Circle2 from '../../assets/data/images/3d objdcts/circle2.png'
import Line from '../../assets/data/images/line-4.png'


const About = ({mode}) => {

  const [themeMode, setThemeMode] = useState(mode)
    
  useEffect(() => {
    setThemeMode(mode)
    
  }, [mode])

  return (
    <section className={`relative flex  pb-4 bg-backgroundTheme dark:bg-primaryColor relative ${themeMode?"dark":""} `}>
      <div className="images ">
      <span
       data-aos="fade-down-left"
       data-aos-duration="1500"
       data-aos-delay="200"
       className='bubble-img absolute w-[15%]  left-[-4%] max-sm:hidden'>
        <img className='z-0  w-full' src={Circle2} alt="" />
      </span>
      <span className='absolute top-6 left-0'>
        <img src={Line} alt="" />
      </span>
      </div>
    <div className='container' id='about'>

      {/* <img src="heroImg" alt="" /> */}
      <div className=" pt-14 ">
        <div className="md:flex items-center  space-between sm:flex-col md:flex-row">
          {/* ============= hero left content ============= */}

          <div className="w-full md:basis-1/2 my-4">
            {/* <h5 data-aos="fade-right" data-aos-durantion="2000" className='text-headingColor font-[600]'>Hello World!</h5> */}

            <h1
              data-aos="fade-up"
              data-aos-duration="1000"
              className={`text-headingColor font-[800] text-[1.8rem] sm:text-[40px] my-3 ${themeMode?"text-primaryColor": ""} `}
            >
              <p className='font-[400] text-[1.4rem] text-secondaryHeading'> Featured case study </p>
              <h1 className='text-[2.8rem]'> We Design, Develop and Write.</h1> <br />
              <p className='font-[400] text-[1.1rem] mb-4'>
                <h3> <strong> Maximize Your Online Presence with Axzo Tech's Website Development Services!  </strong></h3>
                Your website is the digital face of your business, and at Axzo Tech, we understand the importance of making a strong impression. Our team of skilled developers will work closely with you to create a custom website that reflects your brand identity and engages your target audience. Let's build a website that drives conversions and helps you achieve your business goals! Enhance your online presence, boost conversions, achieve business goals.!</p>
              {/* <p className='font-[400] text-[1.4rem]'> <p className='inline text-secondaryHeading text-[1.8rem]'>AXZO Tech</p>-Your Partner for Exceptional Web Design and Development</p>   */}
            </h1>
            <br />

            <div className=" hidden  w-30 sm:block left-48 bg-buttonColor h-1 right-1/2 mt-4"> </div>

            <h1
              data-aos="fade-up"
              data-aos-duration="1000"
              className='text-headingColor font-[800] text-[1.8rem] sm:text-[40px] my-3'
            >
              <br />
              <p className={`font-[400] text-[1.1rem] mb-4 ${themeMode?"text-primaryColor": ""}`}>
                <h3> <strong> Enhance Your Brand's Identity with Axzo Tech's Branding Solutions!  </strong></h3>
                Your brand is more than just a logo – it's the essence of your business. At Axzo Tech, we offer comprehensive branding solutions to help you establish a strong and memorable brand identity. From logo design to brand guidelines, we'll work with you to develop a cohesive brand strategy that resonates with your target audience. Let's elevate your brand and leave a lasting impression. Brand identity, logo design, brand guidelines, cohesive brand strategy, establish strong brand, memorable brand identity, resonate with target audience, elevate your brand, lasting impression.</p>
              {/* <p className='font-[400] text-[1.4rem]'> <p className='inline text-secondaryHeading text-[1.8rem]'>AXZO Tech</p>-Your Partner for Exceptional Web Design and Development</p>   */}
            </h1>

            <div
              // data-aos="fade-up"
              // data-aos-duration="1800"
              // data-aos-delay="200"
              className='flex items-center gap-6 mt-4 mb-9'
            >
              <a href="#contact">
                <button className=' button bg-buttonColor py-3 px-2 text-white font-[500] flex items-center gap-2 
              hover:bg-smallTextColor ease-in duration-300 py-1.5 px-4 rounded-[8px]'>
                  <i className="ri-discuss-line"></i> Contact Us
                </button>
              </a>

            </div>





          </div>

          {/* ============= hero left end ============= */}

          {/* ============ hero right ================== */}

          <div className="basis-1/2  sm-mt-0">
            <figure className="flex items-center justify-center " style={{ width: "40vw" }}>
              {/* <img className='hero-img absolute left-100 top-10' src={windowImg} alt="logo img" style={{width:"20%"}} /> */}
              <img className={`hero-img absolute right-10 bottom-15 ${!themeMode?"shadow-img":""}`} src={MobileImg} alt="logo img" style={{ width: "45%" }} />
              {/* <img className='hero-img absolute left-140' src={homeImg} alt="logo img" style={{width:"30%"}} /> */}
              {/* <img src="" alt="" /> */}
            </figure>
          </div>
        </div>

      </div>

      {/* =============== hero img end ============== */}

    </div>
    </section>
  )
}

export default About