
import React from 'react';
import '../../App.css'

const Footer = () => {
  return (
    <footer className='bg-blackNav pt-12 pb-8'>
      {/* footer-top */}
      <div className="container">
        <div className='sm:flex items-center justify-between md:gap-8'>
          <div className='w-full sm:w-1/2'>
            <h2 className='text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]'>
              We Make your Online Presence Possible.
            </h2>

            <a href="#contact">
              <button className=' button flex items-center gap-2 text-white bg-buttonColor font-[500]  py-3 px-3 rounded-[8px] max-h-[45px]  
                            hover:bg-smallTextColor hover:text-white hover: font-[500] ease-in duration-300' >
              <i className="ri-discuss-line"></i> Get Pricing               
              </button>
            </a>
          </div>

          <div className='w-full sm:w-1/2'>
            <p className='text-gray-300 leading-7 mt-4 sm:mt-0'>
              At AXZO TECH, we specialize in creating stunning, user-friendly websites that elevate your online presence. Let us transform your digital landscape and help you reach your online goals.
            </p>
<br />
            <p className='text-gray-300 leading-7 mt-4 sm:mt-0'>
            <i class="ri-copyright-line"></i> 2024 - All rights reserved by <h1 className='text-[1rem] text-secondaryHeading inline'> Axzo Tech. </h1>
            </p>



          </div>

        </div>

        <div>
          <ul className='flex items-center justify-center gap-10 mt-10'>
            <li>
              <a className='text-gray-200 font-[400]' href="#about">About</a>
            </li>
            <li>
              <a className='text-gray-200 font-[400]' href="#services">Services</a>
            </li>
            <li>
              <a className='text-gray-200 font-[400]' href="#contact">Get Pricing!</a>
            </li>

          </ul>
        </div>
      </div>

    </footer>
  )
}

export default Footer;
