import UpperArrow from '../../assets/data/images/upper-arrow.png'
import React from 'react'

const BackToTop = () => {
  return (
    <div className='relative'>
    <a href="#home"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-delay="300"
    >
      <span className='up-btn fixed bottom-14 right-10 z-40 '>
        <button className='bg-buttonColor flex justify-center items-center rounded-[50%] text-white w-[2.5rem] h-[2.5rem] max-md:w-[1.8rem] max-md:h-[1.8rem] border-rounded hover:bg-smallTextColor ease-in duration-200'>
          <img className='w-[50%] max-md:w-[1rem]' src={UpperArrow} alt="arrow" />
        </button>
      </span>
    </a>
    </div>
  )
}

export default BackToTop