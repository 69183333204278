import { useEffect, useState } from 'react';
import Aos from 'aos';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Hero from './components/UI/Hero';
import Services from './components/UI/Services';
// import Products from './components/UI/Products';
import Contact from './components/UI/Contact';
import About from './components/UI/About';
import Portfolio from './components/UI/Portfolio';
import Reviews from './components/UI/Reviews';
import AnimaCursor from "../src/components/miniComponents/AnimatedCursor"

import Button from './components/miniComponents/Button';
import BackToTop from './components/miniComponents/BackToTop';
// import Background from "./assets/data/images/bg-medicine.jpg"


function App() {
  useEffect(()=> {
    Aos.init();
  },[])

  const [darkMode, setdarkMode] = useState(false)

  const toggleMode = () => {
    setTimeout(() => {
        setdarkMode(!darkMode)
        document.documentElement.classList.toggle('dark')
    }, 500);
}



  return (
    <div >
    
      <Button mode={darkMode} onclick={()=> toggleMode()} />
      <BackToTop />
      <Header mode={darkMode}/>
      <main>
      <AnimaCursor mode={darkMode}/>
        <Hero mode={darkMode}/>
        <Services mode={darkMode}/>
        <About mode={darkMode}/>
        <Portfolio mode={darkMode}/>
        <Reviews mode={darkMode}/>
        <Contact mode={darkMode}/>
      </main>
      <Footer mode={darkMode}/>
    </div>
  );
}

export default App;
