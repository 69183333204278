
import React, {useState, useEffect} from 'react'
import Bubble from '../../assets/data/images/3d objdcts/3.png'
import Line from '../../assets/data/images/line-4-blue.png'
import Fb from '../../assets/data/images/facebook.png'
import Ins from '../../assets/data/images/instagram.png'
import Disc from '../../assets/data/images/discord.png'
import Skype from '../../assets/data/images/skype.png'
import Linkedin from '../../assets/data/images/linkedin-removebg-preview.png'


import '../../App.css';


const Contact = ({mode}) => {

  const [themeMode, setThemeMode] = useState(mode)

  useEffect(() => {
    setThemeMode(mode)
    
  }, [mode])

  return (
    <section id='contact' className={`pb-16  relative ${themeMode?"darkCross": ""}`}>
      <div className='images w-[5%] '>
      <span 
      data-aos="fade-down-left"
      data-aos-duration="1500"
      data-aos-delay="300"
      className='bubble-img absolute w-[230px]  left-[-60px] max-sm:hidden'>
        <img className='z-0 opacity-75' src={Bubble} alt="" />
      </span>
      <span className='absolute z-0 top-10 '>
        <img src={Line} className='opacity-50' alt="" />
      </span>
      </div>
      <div className="container z-30">

        <h2 className='text-secondaryHeading font-[500] text-[1.8rem]  ml-8 z-20'>Connect with us!</h2>
        <div className="md:flex justify-between items-center relative">
          <div className="w-full z-30 md:w-1/2 h-auto sm:h-[550px] font-[800] text-[1.8rem] sm:text-[40px] mb-20 mx-4">
            <h1>We do writing, designing & development.</h1>
            <p className='font-[400] text-[1.3rem] mb-20 max-md:text-[0.9rem] '>
              At Axzo Tech, we are your comprehensive solution for all your writing, designing, and development needs. With a team of skilled professionals dedicated to excellence, we specialize in crafting engaging written content, creating captivating designs, and developing innovative solutions tailored to your specific requirements. Whether you need compelling article/blogs writing, ebook formatting, eye-catching UI UX design of website, SaaS & mobile app, or cutting-edge website development, we have the expertise and creativity to bring your vision to life. Partner with Axzo Tech for top-notch services that exceed expectations and propel your projects to success.
            </p>
          </div>

          <div className={`w-full h-auto py-4 lg:mb-48 basis-1/2 z-10 md:mt-0 md:w-1/2 lg:flex items-center  px-4 lg:px-8 rounded-[15px] ${themeMode?"boxInDark":"bg-backgroundTheme"} `}>

            <form className='w-full h-fit-content' action="https://getform.io/f/nbdoqzka" method="POST">
              <h1 className={`text-black text-[1.8rem] font-[500] text-center my-2 ${themeMode?"text-white":""}`}> Get Connect With Us!</h1>
              <div className="mb-5">
                <input type="text" required name='name' placeholder='Your Name' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>
              <div className="mb-5">
                <input type="email" required name='email' placeholder='Your Email' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>
              <div className="mb-5">
                <input type="text" name='number' placeholder='Your Phone Number' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>
              <div className="mb-5">
                <textarea type="text" required name='message' rows={5} placeholder='How can we help you?' className='w-full p-3 focus:outline-none rounded-[5px]' />
              </div>
              <button className='w-full p-3 bg-secondaryHeading focus:outline-none rounded-[5px]  text-[1.2rem] text-white  hover:bg-smallTextColor text-center ease-linear duration-150'>
                Get Pricing Now
              </button>


            </form>
          </div>
        </div>
        </div>


        <div className="container mt-34 flex items-center justify-center gap-40  ">
         
          <button className='flex items-center gap-1  font-[600] rounded max-h-[30px]' >
            <a href="https://www.facebook.com/profile.php?id=100088242094302">
              <img className={`social-icons`} src={Fb} alt="" width={45} style={{}}/>
            </a>
          </button>

          <button className='flex items-center gap-1 font-[600] rounded max-h-[30px] 
                            ease-in duration-300' >
            <a href="https://www.linkedin.com/company/axzotech/mycompany/"
             >
              <img  className={`social-icons`} src={Linkedin} alt="" width={60} />
            </a>
          </button>

          <button className='flex items-center gap-1  font-[600] rounded max-h-[30px] 
                            ease-in duration-300'>
            <a href="https://discord.com/invite/eQMwugMM"       >
              <img  className={`social-icons`} src={Disc} alt="discord" width={50} />
            </a>
          </button>

          <button className='flex items-center gap-1  font-[600] rounded max-h-[30px] 
                            ease-in duration-300'>
            <a href="https://join.skype.com/invite/peT5gO7lOD3E"       >
              <img  className={`social-icons`} src={Skype} alt="skype" width={50} />
            </a>
          </button>

          <button className='flex items-center gap-1  font-[600] rounded max-h-[30px] 
                            ease-in duration-300' >
            <a href="https://www.instagram.com/axzotech?igsh=OWVkMTFiN2gwcnB3"       >
              <img  className={`social-icons`} src={Ins} alt="instagram" width={50} />
            </a>
          </button>

        </div>
        
    </section>
  )
}

export default Contact
