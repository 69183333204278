import React, { useEffect, useRef, useState } from 'react'

import ThumbnailBr from '../../assets/data/images/Projects/Branding/branding_thumbnail.png'
import Branding1 from '../../assets/data/images/Projects/Branding/Wahum Restaurant 1.png'

import Branding1Pdf from '../../assets/data/files/branding.pdf'
import Branding2Pdf from '../../assets/data/files/WahumRestaurant.pdf'

import ThumbnailApp from '../../assets/data/images/Projects/App_Design/image_2024_05_02T14_16_24_080Z.webp'
import AppDesign1 from '../../assets/data/images/Projects/App_Design/image_2024_05_02T14_16_24_077Z.webp'
import AppDesign2 from '../../assets/data/images/Projects/App_Design/image_2024_05_02T14_16_24_082Z.webp'
import AppDesign3 from '../../assets/data/images/Projects/App_Design/5.webp'

import ThumbnailWeb from '../../assets/data/images/Projects/Web_design/H2 Fuel Website.webp'
import Webdesign1 from '../../assets/data/images/Projects/Web_design/Bakery Website.webp'
import Webdesign2 from '../../assets/data/images/Projects/Web_design/Plumbing website Thumb.png'
import Webdesign3 from '../../assets/data/images/Projects/Web_design/Restaurant Website.png'

import Mockups from '../../assets/data/images/Projects/Mockups/2.png'
import Mockupimg1 from '../../assets/data/images/Projects/Mockups/1.png'
import Mockupimg2 from '../../assets/data/images/Projects/Mockups/4.png'
import Mockupimg4 from '../../assets/data/images/Projects/Mockups/8.jpg'

import Blog from '../../assets/data/images/Projects/blog.webp'
import Article from '../../assets/data/images/Projects/article.webp'
import Script from '../../assets/data/images/Projects/script writing.png'
import BookFormat from '../../assets/data/images/Projects/book cover.webp'

import BlogPdf from '../../assets/data/files/Morningwalk.pdf'
import ArticlePdf from '../../assets/data/files/Earthhasafifty.pdf'
import ScriptPdf from '../../assets/data/files/charliechaplin.pdf'

import Card from '../../components/miniComponents/CardBox'

import RightArrow from '../../assets/data/images/next btn.png'
import LeftArrow from '../../assets/data/images/previous-btn.png'


const Portfolio = ({mode}) => {

    
    useEffect(() => {
      setThemeMode(mode)
      
    }, [mode])

    const cardsData = [

        {
            "id": 'app_designs',
            "thumbnail": true,
            "imgUrl": ThumbnailApp,
            "title": 'Application Design: "Webolute"',
            "description": "Hello everyone! \n  We would like to share a preview of our designed Application for 'Webolute'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'app_designs',
            "thumbnail": false,
            "imgUrl": AppDesign1,
            "title": 'Application Design: "Quiz App"',
            "description": "Hello everyone! \n  We would like to share a preview of our designed Application for 'Quiz App'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },
       
        {
            "id": 'app_designs',
            "thumbnail": false,
            "imgUrl": ThumbnailWeb,
            "title": 'Website Design: "H2 Station"',
            "description": "Hello everyone! \n  We would like to share a preview of our designed Website for 'H2 Station'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'app_designs',
            "thumbnail": false,
            "imgUrl": AppDesign2,
            "title": 'Application Design: "NON STOP Restaurant"',
            "description": "Hello everyone! \n  We would like to share a preview of our designed Application for 'Non Stop'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'app_designs',
            "thumbnail": false,
            "imgUrl": AppDesign3,
            "title": 'Application Design: "NON STOP Restaurant"',
            "description": "Hello everyone! \n  We would like to share a preview of our designed Application for 'Non Stop'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'web_designs',
            "thumbnail": false,
            "imgUrl": Webdesign1,
            "title": 'Website Designing: "Buns & Roses Bakers"',
            "description": "Hello everyone! \n  We would like to share a preview of our developed Website for 'Buns & Roses'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'web_designs',
            "thumbnail": false,
            "imgUrl": Webdesign2,
            "title": 'Website Designing: "Apple Plumbing"',
            "description": "Hello everyone! \n  We would like to share a preview of our developed Website for 'Apple Plumbing'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'web_designs',
            "thumbnail": false,
            "imgUrl": Webdesign3,
            "title": 'Website Designing: "WokSoy Restaurant"',
            "description": "Hello everyone! \n  We would like to share a preview of our developed Website for 'WokSoy Restaurant'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence..",
        },

        {
            "id": 'web_designs',
            "thumbnail": true,
            "imgUrl": ThumbnailWeb,
            "title": 'Website Design: "H2 Station"',
            "description": "Hello everyone! \n  We would like to share a preview of our developed Website for 'H2 Station'. For a detailed preview of the screens, you can Message us. We just have a goal of making it easy for users to find best ways to enhance their online existence.",
        },


        {
            "id": 'branding',
            "thumbnail": true,
            "imgUrl": ThumbnailBr,
            "title": 'Brand Identity: "Elephent Coffee" ',
            "description": `Hello everyone! \n  We would like to share a preview of our designed Brand Identity for Elephent Coffee.We just have a goal of making it easy for users to find best ways to enhance their online existence.  For a detailed preview of the screens, click on the image. `,
            "download": true,
            "file": Branding1Pdf,
        },

        {
            "id": 'branding',
            "thumbnail": false,
            "imgUrl": Branding1,
            "title": 'Brand Identity: "Wahum Restaurant" ',
            "description": `Hello everyone! \n  We would like to share a preview of our designed Brand Identity for Wahum Restaurant. We just have a goal of making it easy for users to find best ways to enhance their online existence.  For a detailed preview of the screens, click on the image. `,
            "download": true,
            "file": Branding2Pdf,
        },


        {
            "id": 'packaging_designs',
            "thumbnail": true,
            "imgUrl": Mockups,
            "title": 'Mockup: "Dot."',
            "description": "Mockups visually depict website designs, while prototypes provide interactive simulations to test usability and functionality before development.",
        },

        {
            "id": 'packaging_designs',
            "thumbnail": false,
            "imgUrl": Mockupimg1,
            "title": 'Mockup:"Profit 8 Book Cover"',
            "description": "Mockups visually depict website designs, while prototypes provide interactive simulations to test usability and functionality before development.",
        },

        {
            "id": 'packaging_designs',
            "thumbnail": false,
            "imgUrl": Mockupimg2,
            "title": 'Mockup:"Box Packaging"',
            "description": "Mockups visually depict website designs, while prototypes provide interactive simulations to test usability and functionality before development.",
        },

        {
            "id": 'packaging_designs',
            "thumbnail": false,
            "imgUrl": Mockupimg4,
            "title": 'Mockup: "Mobile Phone Device"',
            "description": "Mockups visually depict website designs, while prototypes provide interactive simulations to test usability and functionality before development.",
        },


        {
            "id": 'content_writing',
            "thumbnail": true,
            "imgUrl": Blog,
            "title": 'Blog: "Morning Walk"',
            "description": " Our blog's got you covered with everything from interesting stories to helpful tips. Contact us for engaging Blog writing, and let's explore together!.",
            "download": true,
            "file": BlogPdf,
        },

        {
            "id": 'content_writing',
            "thumbnail": false,
            "imgUrl": Article,
            "title": 'Article: "Global Warming"',
            "description": "Our articles cover a variety of topics, delivering valuable insights and practical advice.  Contact us for engaging Article writing, and let's explore together!",
            "download": true,
            "file": ArticlePdf,
        },

        {
            "id": 'content_writing',
            "thumbnail": false,
            "imgUrl": Script,
            "title": 'Youtube Video Script',
            "description": "Our scripts are specialized to captivate, entertain, and inspire. Whether it's for shorts, film, TV, or big screens, we specialize in creating engaging scripts. Get ready for an unforgettable storytelling experience!.",
            "download": true,
            "file": ScriptPdf,
        },

        {
            "id": 'content_writing',
            "thumbnail": false,
            "imgUrl": BookFormat,
            "title": 'Book Formatting (Kindle & Paperback)',
            "description": "We specialize in book formatting with our professional work tailored for both Kindle e-books and paperback editions. From digital to print, we ensure your book maintains its integrity across all formats, for a seamless reading experience.\nNote: Due to a non-disclosure agreement (NDA) with the client, we are unable to provide a preview of this book.  ",
            "download": true,
            "file": ScriptPdf,
        },

    ]


    // const [initalArray, setInitialArray] = useState()
    const [finalArray, setfinalArray] = useState(cardsData)
    const [scrollPosition, setscrollPosition] = useState(0)
    const [themeMode, setThemeMode] = useState(mode)
    const [leftbtnDisable, setleftbtnDisable] = useState(false)
    const [rightbtnDisable, setrightbtnDisable] = useState(false)
    const [selectedButton, setSelectedButton] = useState('all');


    const shuffleCards = (idG) => {
        setSelectedButton(idG);
            if (cardsData.length > 0) {
            const filteredCards = cardsData.filter((elem) => elem.id === idG)
            // console.log(filteredCards)
            setfinalArray(filteredCards);
        }

    }

   


   

    useEffect(() => {
        initialCardsState()
    }, [])

    const initialCardsState = () => {
        if (cardsData.length > 0) {
            const initialCards = cardsData.filter((elem) => elem.thumbnail === true)
            // console.log(initialCards)
            setfinalArray(initialCards);
        }
    }
    
    useEffect(() => {
        disableBtnFn()
    }, [scrollPosition])
 
    const disableBtnFn = () => {
        if (scrollPosition === 0){
            setleftbtnDisable(true);
        }else{
            setleftbtnDisable(false)
        };

        let totalItemWidth = (finalArray.length-1) * ITEM_WIDTH;
        if(scrollPosition >= totalItemWidth){
            setrightbtnDisable(true)
        }else{
            setrightbtnDisable(false)
        };

    }

    const cardLg = 540;
    const cardSm = 230;
  
    let ITEM_WIDTH;
  
    if(window.innerWidth < 768){
    ITEM_WIDTH = cardSm;
  }else{
    ITEM_WIDTH = cardLg;
  }

    const boxRef = useRef();

    // Function to handle scrolling on button click. 

    const handleScroll = (scrollAmount) => {
        // Calculate the new scroll position
        const newScrollPosition = scrollPosition + scrollAmount;

        // Update the state with the new scroll position
        setscrollPosition(newScrollPosition);

        // Access the container element and set its scrollLeft property
        boxRef.current.scrollLeft = newScrollPosition;

        
    };


    return (
        <section className={` relative py-3 max-md:px-2 ${themeMode?"darkCross":""} `} id='portfolio'>
        <div className='container' >
            <div className=" pt-14  ">
                <div className="sm:flex-col md:flex-row">
                    {/* ============= hero left content ============= */}

                    <div className="w-full md:basis-1/2 my-4">

                        <h1
                            data-aos="fade-up"
                            data-aos-duration="1500"
                            className=' font-[800] text-[1.8rem] sm:text-[40px] my-3'
                        >
                            <p className='font-[400] text-[1.4rem] text-secondaryHeading'> Our Portfolio </p>
                            <h1 className={`text-[2.8rem]`}> Our Previous Projects</h1> <br />

                        </h1>

                    </div>

                    {/* ============= hero upper end ============= */}


                    {/* ============ hero lower start (Cards) ================== */}

                    
                        {/* Sectional Buttons */}
                        {/* <div className={`flex items-center justify-between mb-4 max-md:grid max-md:grid-cols-3 gap-10`}>
                            <button id='all' className='listing my-5 text-[1.2rem] font-[500] focus:font-[600]  hover:underline decoration-solid   ' onClick={() => setfinalArray(cardsData)} >
                                <span>All</span>
                            </button>

                            <button id='app_designs' className='listing  my-5 text-[1.2rem] font-[500] focus:underline decoration-solid hover:underline decoration-solid ' onClick={() => shuffleCards('app_designs')}>
                                <span>UI/UX Designs</span>
                            </button>

                            <button id='web_designs' className='listing  my-5 text-[1.2rem] font-[500] focus:font-[600] hover:underline decoration-solid ' onClick={() => shuffleCards('web_designs')}>
                                <span>Web Development</span>
                            </button>

                            <button id='branding' className='listing  my-5 text-[1.2rem] font-[500] focus:font-[600] hover:underline decoration-solid    ' onClick={() => shuffleCards('branding')} >
                                <span>Brand Identity</span>
                            </button>

                            <button id='content_writing' className='listing   my-5 text-[1.2rem] font-[500] focus:font-[600] hover:underline decoration-solid    ' onClick={() => shuffleCards('content_writing')}>
                                <span>Writing Services</span>
                            </button>

                            <button id='packaging_designs' className=' listing  my-5 text-[1.2rem] font-[500] focus:font-[600] hover:underline decoration-solid  ' onClick={() => shuffleCards('packaging_designs')}>
                                <span>Mockups</span>
                            </button>
                        </div> */}

<div className={`flex items-center justify-between mb-4 max-md:grid max-md:grid-cols-3 gap-10`}>
            <button id='all' className={`listing my-5 text-[1.2rem] font-[500] focus:font-[600] ${selectedButton === 'all' ? 'underline font-[600]' : ''}`} onClick={() => {setfinalArray(cardsData); setSelectedButton("all")}}>
                <span>All</span>
            </button>

            <button id='app_designs' className={`listing my-5 text-[1.2rem] font-[500] ${selectedButton === 'app_designs' ? 'underline font-[600]' : ''}`} onClick={() =>shuffleCards('app_designs')}>
                <span>UI/UX Designs</span>
            </button>

            <button id='web_designs' className={`listing my-5 text-[1.2rem] font-[500] ${selectedButton === 'web_designs' ? 'underline font-[600]' : ''}`} onClick={() =>shuffleCards('web_designs')}>
                <span>Web Development</span>
            </button>

            <button id='branding' className={`listing my-5 text-[1.2rem] font-[500] ${selectedButton === 'branding' ? 'underline font-[600]' : ''}`} onClick={() =>shuffleCards('branding')}>
                <span>Brand Identity</span>
            </button>

            <button id='content_writing' className={`listing my-5 text-[1.2rem] font-[500] ${selectedButton === 'content_writing' ? 'underline font-[600]' : ''}`} onClick={() =>shuffleCards('content_writing')}>
                <span>Writing Services</span>
            </button>

            <button id='packaging_designs' className={`listing my-5 text-[1.2rem] font-[500] ${selectedButton === 'packaging_designs' ? 'underline font-[600]' : ''}`} onClick={() =>shuffleCards('packaging_designs')}>
                <span>Mockups</span>
            </button>
        </div>

                        {/* Cards */}

                        {/* <div className='grid grid-cols-2 gap-4 md:grid max-sm:grid-cols-1 max-sm:w-[50vw] max-sm:gap-2 '> */}


                    
                </div>
            </div>

            <div className='w-full flex items-center gap-2 max-md:w-full max-md '>
                        
                    <span>
                        <button id='left_btn' onClick={()=> {handleScroll(-ITEM_WIDTH)}} className={`bg-buttonColor flex justify-center items-center rounded-[50%] text-white w-[2rem] h-[2rem] border-rounded hover:bg-smallTextColor ease-in duration-200 ${leftbtnDisable?"hidden":""}`} ><img src={LeftArrow} width={"15px"} alt="" /></button>
                        </span>
                        
                        <div
                        ref={boxRef}
                        className={`flex bg-backgroundTheme w-full p-3 overflow-hidden scroll-smooth max:md-w-[550px] max-sm:gap-2 border-none rounded-[20px] ${themeMode?"darkCross":""} `}>

                            {
                                finalArray &&
                                finalArray.map((element) => (
                                    // console.log(element)
                                    <Card  element={element} darkMode={themeMode} />)
                                )
                            }



                        </div>
                        <span>
                        <button onClick={()=> {handleScroll(ITEM_WIDTH)}} className={`bg-buttonColor flex justify-center items-center rounded-[50%] text-white w-[2rem] h-[2rem] border-rounded hover:bg-smallTextColor ease-in duration-200 ${rightbtnDisable?"hidden":""}`} ><img src={RightArrow} width={"15px"} alt=""  /></button>
                        </span>
                        </div>
        </div>
        </section>
    )
}

export default Portfolio