import Card from 'react-bootstrap/Card';
import '../../App.css'
import React, {useState, useEffect} from 'react'


function CardBox({element, onClick , darkMode, width, cardType}) {

  const [themeMode, setThemeMode] = useState(darkMode)
    
    useEffect(() => {
      setThemeMode(darkMode)
      
    }, [darkMode])
    


    // console.log(element)
  return (
    <a href={element.download === true? element.file : ""} target='_blank'  onClick={(e)=> element.download!==true? e.preventDefault(): null} >
    <Card  className={`${cardType==="review"?"z-30 hover:-translate-y-1 hover:scale-110 duration-300 drop-shadow w-[700px] max-sm:w-[240px]":"shadow w-[490px] lg:h-[580px] max-sm:w-[220px]  max-md:h-[400px] max-md:overflow-y-scroll"} m-5 my-5 card overflow-hidden h-auto  max-sm:m-0 ${darkMode?"shadowForDark":""}  `} onClick={onClick}  >

      {/* <Carousel images={element.thumbnail} /> */}
      <Card.Img variant="top" src={element.imgUrl} className={`w-full  mb-5 contain transition ease-in-out delay-150 ${cardType==="review"?"":"hover:-translate-y-1 hover:scale-110 duration-300"} `} />
      <Card.Body>
        <Card.Title className='m-4 text-[1.3rem] font-[600]'>{element.title}</Card.Title>
        <Card.Text className='m-4 text-[1rem]'>
          {element.description}
        </Card.Text>
      </Card.Body>
    
    </Card>
    </a>
  );
}

export default CardBox;