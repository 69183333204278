import React from 'react'
import darkImg from '../../assets/data/images/forDark.webp'
import lightImg from '../../assets/data/images/ForLight.webp'

const Button = ({mode, onclick}) => {


    return (
        <div>
            <div className="relative">
                <button onClick={onclick} className={`ease-in duration-300 fixed mt-3 z-50 max-md:top-5 max-md:right-20 right-5  h-fit`} >
                    <img src={mode ? darkImg : lightImg} className='w-[60px] max-md:w-[40px]' alt="mode" />
                </button>
            </div>
        </div>
    )
}

export default Button