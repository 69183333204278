import React, { useState, useRef, useEffect} from 'react'

import Ring from '../../assets/data/images/3d objdcts/ring.png'
import Line from '../../assets/data/images/line-4.png'

import LeftArrow from '../../assets/data/images/previous-btn.png'
import RightArrow from '../../assets/data/images/next btn.png'

import Card1 from '../../assets/data/images/Reviews/Frame 2.png'
import Card2 from '../../assets/data/images/Reviews/Frame 3.png'
import Card3 from '../../assets/data/images/Reviews/Frame 4.png'
import Card4 from '../../assets/data/images/Reviews/Frame 5.png'
import Card5 from '../../assets/data/images/Reviews/Frame 7.png'
import Card7 from '../../assets/data/images/Reviews/Frame 9.png'
import Card8 from '../../assets/data/images/Reviews/Frame 10.png'
import Card9 from '../../assets/data/images/Reviews/Frame 11.png'

import Card from '../../components/miniComponents/CardBox'


const Reviews = ({mode}) => {

  const cards = [
    {
      "id": "1",
      "imgUrl": Card1,
    },
    {
      "id": "2",
      "imgUrl": Card2,
    },
    {
      "id": "3",
      "imgUrl": Card3,
    },
    {
      "id": "4",
      "imgUrl": Card4,
    },
    {
      "id": "5",
      "imgUrl": Card5,
    },
    {
      "id": "7",
      "imgUrl": Card7,
    },
    {
      "id": "8",
      "imgUrl": Card8,
    },
    {
      "id": "9",
      "imgUrl": Card9,
    },

  ];


  const [scrollPosition, setscrollPosition] = useState(0)
  const [themeMode, setThemeMode] = useState(mode)
  const [leftbtnDisable, setleftbtnDisable] = useState(false)
  const [rightbtnDisable, setrightbtnDisable] = useState(false)
  const [finalArray, setfinalArray] = useState(cards)

  useEffect(() => {
    setThemeMode(mode)
    
  }, [mode]);

  useEffect(() => {
    finalArrayFn()
  }, [])

  const finalArrayFn = () => {
    setfinalArray(cards)
  }  

  useEffect(() => {
    disableBtnFn()
}, [scrollPosition])

const disableBtnFn = () => {
    if (scrollPosition === 0){
        setleftbtnDisable(true);
    }else{
        setleftbtnDisable(false)
    };

    let totalItemWidth = (finalArray.length-1) * ITEM_WIDTH;
    if(scrollPosition >= totalItemWidth){
        setrightbtnDisable(true)
    }else{
        setrightbtnDisable(false)
    };

}


  const cardLg = 740;
  const cardSm = 250;

  let ITEM_WIDTH;

  if(window.innerWidth < 768){
  ITEM_WIDTH = cardSm;
}else{
  ITEM_WIDTH = cardLg;
}

  const containerRef = useRef();

  // Function to handle scrolling on button click. 

  const handleScroll = (scrollAmount) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;

    // Update the state with the new scroll position
    setscrollPosition(newScrollPosition);

    // Access the container element and set its scrollLeft property
    containerRef.current.scrollLeft = newScrollPosition;

  };

  return (
    <section id='review' className={` py-10 relative bg-backgroundTheme ${themeMode?"dark":""} `}>
    <div >
      <span 
      data-aos="fade-down-left"
      data-aos-duration="1500"
      data-aos-delay="300"
      className='bubble-img mr-4  absolute w-[250px] left-[-90px] top-0 opacity-25 max-sm:hidden z-0'>
        <img  src={Ring} alt="" />
      </span>
      <span className='absolute top-14 left-0 z-10 opacity-75'>
        <img  src={Line} alt="" />
      </span>

      <div className='container ml-8 z-30'>
        <h1
          className={`text-headingColor font-[800] text-[1.8rem] sm:text-[40px] m-3 ${themeMode?"lightText":""} `}
        >
          <p className='font-[400] text-[1.6rem] text-secondaryHeading z-10'> Client's Feedback </p>
          <h1 className='text-[2.8rem]'> Some of Our Client's Remarks</h1> <br />

        </h1>

        </div>

        {/* <div className=" grid grid-cols-3 gap-2 relative max-md:items-center max-md:block max-sm:grid-cols-1 "> */}
        {/* <div className="flex object-cover h-[500px] justify-center "> */}
          
          <div className=' pb-4 container w-[890px] flex items-center space-between max-sm:w-full h-fit'>

            <span>
              <button id='left_btn' onClick={() => { handleScroll(-ITEM_WIDTH) }} className={`mx-1 bg-buttonColor flex justify-center items-center rounded-[50%] text-white w-[2rem] h-[2rem] border-rounded hover:bg-smallTextColor ease-in duration-200 ${leftbtnDisable?"hidden": ""}`}><img src={LeftArrow} width={"15px"} alt="" /></button>
            </span>

            <div
              ref={containerRef}
              className={`flex flex-row w-fit p-3	overflow- scroll-smooth max-sm:w-fit align-items-center w-[65vw] p-3 overflow-hidden scroll-smooth max-sm:w-100 max-sm:gap-2 border-none rounded-[30px] ` }>
              {
                finalArray && finalArray.map((elem) => (

                  <Card element={elem} darkMode={mode} width={"800px"} cardType={"review"} />

                )

                )
              }



            </div>
            <span>
              <button id='leftArrow' onClick={() => { handleScroll(ITEM_WIDTH) }} className={`mx-1 bg-buttonColor flex justify-center items-center rounded-[50%] text-white w-[2rem] h-[2rem] border-rounded hover:bg-smallTextColor ease-in duration-200 ${rightbtnDisable? "hidden": ""}`} ><img src={RightArrow} width={"15px"} alt="" /></button>
            </span>


        </div>
    </div>
    </section>
  )
}

export default Reviews