
import React, {useState, useEffect} from 'react'
import WebStrings from '../../assets/data/images/3d objdcts/2.png'
import '../../App.css'

const Services = ({mode}) => {

  const [themeMode, setThemeMode] = useState(mode)
    
  useEffect(() => {
    setThemeMode(mode)
    
  }, [mode])

  return (
    <section id="services" className={` ${themeMode?'darkCross': ''} py-8`}>
      <div className={`  relative lg:pt-5 container `}>
        <span className='absolute top-60 left-28'>
          <img className='opacity-25' src={WebStrings} alt=""  width={"80%"} />
        </span>
        <div className="text-center mt-12">
          <h2 className="text-secondaryHeading font-[700] text-[2.4rem] mb-3">
            What we can do for you?
          </h2>
          <p className={ `lg:max-w-[600px] lg:mx-auto  font-[500] text-[16px] leading-7  `}>
            AXZO TECH. Integrated design and content solutions, we specialize in creating stunning, user-friendly websites that elevate your online presence. Let us transform your digital landscape and help you reach your online goals.
          </p>
        </div>

        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            <div className="relative text-grey-700 antialiased text-sm font-semibold">
              {/* ============== Verical line =============== */}
              <div className="hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform-translate-x-1/2"> </div>
              {/* ============== left card ================== */}
              <div className="mt-6 sm:mt-10 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-start w-full mx-auto items-center" >
                    <div className="w-full sm:w-1/2 sm:pr-8 ">
                      <div 
                      data-aos="fade-down" 
                      data-aos-duration="1200" 
                      className='text-center gradient-box bg-white p-2 rounded shadow group  cursor-pointer ease-in duration-150 '>
                        <h3 className='text-secondaryHeading font-[700] mb-3  group-hover:font-[600] text-2xl'>Web Development</h3>

                        <p className='text-justify text-[15px] font-[500] text-smallTextColor '>
                          Our web development team can help you with everything from start to finish, no matter how big or small the task provided.                           </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ==================== right card ================= */}

              <div className="mt-6 sm:mt-10 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-end w-full mx-auto items-center " >
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div 
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="1300"
                        className='gradient-box bg-white p-3 rounded shadow group cursor-pointer ease-in duration-300'>
                        <h3 className='text-secondaryHeading font-[700] mb-3  group-hover:font-[600] text-2xl text-center'>UI/UX Design </h3>

                        <p className='text-justify text-[15px] font-[500] text-smallTextColor  '>
                          We provide professional UI/UX design services for mobile apps, websites, web apps and Saas software that will take your business to the next level.                                                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ============== left card ================== */}
              <div className="mt-6 sm:mt-10 sm:mb-12 ">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-start w-full mx-auto items-center " >
                    <div className="w-full sm:w-1/2 sm:pr-8 ">
                      <div 
                      data-aos="fade-up" 
                      data-aos-delay="50"
                      data-aos-duration="1200" 
                      className=' gradient-box bg-white p-2 rounded shadow group hover:bg-secondaryHeading cursor-pointer ease-in duration-'>
                        <h3 className='text-secondaryHeading font-[700] mb-3  group-hover:font-[600] text-2xl text-center'>Writing Services</h3>

                        <p className='text-justify text-[15px] font-[500] text-smallTextColor '>
                        Axzo Tech offers expert writing services tailored to boost traffic and engagement. From blogs to articles, YouTube video scripts, and polished book writing/book formatting, we deliver impactful content solutions.                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ==================== right card ================= */}

              <div className="mt-6 sm:mt-10 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">

                  <div className="flex justify-end w-full mx-auto items-center" >
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div 
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="1300"
                        className=' gradient-box bg-white p-3 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150'>
                        <h3 className='text-secondaryHeading font-[700] mb-3  group-hover:font-[600] text-2xl text-center'>Brand Identity </h3>

                        <p className='text-justify text-[15px] font-[500] text-smallTextColor '>
                          Enrich your brand's identity with our bespoke designing services, our designing solutions assist you to captivate and resonate with your audience effortlessly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

       

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
